import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  host: {
    '[class.is-displayed]': 'isLoading',
  },
  imports: [MatProgressSpinnerModule],
})
export class LoadingSpinnerComponent implements OnInit {
  isLoading = false;

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    // Add to the end of the stack to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.loadingService.getLoading().subscribe(isLoading => {
      setTimeout(() => {
        this.isLoading = isLoading;
      });
    });
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: MatSnackBar,
    private oauthService: OAuthService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          // TODO: Renew token
          this.oauthService.logOut();
        }

        this.snackbar.open(
          'Ha habido un error al procesar la petición',
          'Cerrar',
          {
            duration: 5000,
            panelClass: 'error-snackbar',
          },
        );
        return throwError(() => err);
      }),
    );
  }
}

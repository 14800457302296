<h1 mat-dialog-title>Ayuda</h1>
@if (markdownData) {
  <div mat-dialog-content class="dialog-content" markdown [data]="markdownData">
  </div>
} @else {
  <div mat-dialog-content class="dialog-content">
    No hay ayuda disponible para esta página.
  </div>
}

<div class="dialog-actions" mat-dialog-actions>
  <button [mat-dialog-close]="false" mat-button> Cerrar </button>
</div>

import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { debounce, map, of, pairwise, startWith, Subject, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuModel } from '../../../shared/models/menu-parent-section.model';
import { PermissionService } from '../../permissions/permission.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
  ],
})
export class SidebarMenuComponent implements OnInit {
  appData?: { environmentName: string; version: string } = {
    environmentName: environment.environmentName,
    version: environment.version,
  };

  // Used for hovering elements in desktop mode
  changeMenuStatus$ = new Subject<{
    menu: MatMenuTrigger;
    action: 'open' | 'close';
  }>();

  @ViewChildren(MatMenuTrigger) triggers?: QueryList<MatMenuTrigger>;

  userMenu: MenuModel = [];

  constructor(
    private eRef: ElementRef,
    private permissionService: PermissionService,
  ) {}

  @HostListener('document:click', ['$event'])
  browserClick(event: Event) {
    // Click outside the component:
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeAllMenus();
    }
  }

  ngOnInit(): void {
    this.manageMenuActions();
    this.userMenu = this.permissionService.getUserAllowedSections();
  }

  actionMenu(menu: MatMenuTrigger, action: 'open' | 'close'): void {
    this.changeMenuStatus$.next({ menu, action });
  }

  manageMenuActions(): void {
    this.changeMenuStatus$
      .pipe(
        // As we are using pairwise, we need to provide an initial value as the observable won't be
        // fired until there are 2 values
        startWith(null),
        // With pairwise we keep the last 2 events, so we can compare them accordingly
        // [0] => last value, [1] => new value
        pairwise(),
        // Debounce will wait the specified time and process the last one if the requirements are met
        // So if we have a close and open event, it will ignore the first one if the required time has not passed.
        debounce(menuEvents => {
          if (menuEvents[0]?.menu === menuEvents[1]?.menu) {
            if (menuEvents[1]?.action === 'close') {
              // If the menu of the last 2 elements is the same (open/close), we delay the operation
              return timer(100);
            }
          } else if (menuEvents[0]?.action === 'close') {
            // If the menus are different and the action of the previous one was close
            // (we are opening a new menu), we close the previous one before processing the action of new one
            menuEvents[0].menu.closeMenu();
          } else if (
            menuEvents[0]?.action === 'open' &&
            menuEvents[1]?.action === 'open'
          ) {
            menuEvents[0].menu.closeMenu();
          }
          return of({});
        }),
        map(
          menuEvents =>
            menuEvents[1] as { menu: MatMenuTrigger; action: 'open' | 'close' },
        ),
      )
      .subscribe(data => {
        if (data.action === 'open') {
          data.menu.openMenu();
        } else {
          data.menu.closeMenu();
        }
      });
  }

  private closeAllMenus(): void {
    this.triggers?.forEach(trigger => {
      trigger.closeMenu();
    });
  }
}

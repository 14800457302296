import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SearchRepository,
  SearchTextResponseModel,
} from '@garrampa/api-services/garrampa';
import { debounceTime, Subject, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
  MatAutocompleteTrigger,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class ToolbarSearchComponent implements OnInit {
  // searchTextInput and searchText are storing the same value, as by default Angular Material autocomplete
  // overwrites the text input with the option value and we want to keep the original value
  searchTextInput = '';
  searchText = '';
  availableOptions: SearchTextResponseModel[] = [];
  totalAvailableOptions = 0;
  inputChange$ = new Subject<void>();

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  constructor(
    private searchRepository: SearchRepository,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.inputChange$
      .pipe(
        tap(() => {
          // This is used for the autocomplete to avoid it cleaning the input when the user clicks into an option
          // (If the user wants to clear the search, we would receive empty quotes '')
          // We will clean the user input and the available options manually in the search() function
          if (this.searchTextInput !== undefined) {
            this.searchText = this.searchTextInput;
          }
        }),
        debounceTime(300),
      )
      .subscribe(() => {
        if (this.searchTextInput !== undefined) {
          this.search();
        }
      });
  }

  search(): void {
    if (this.searchTextInput && this.searchTextInput.length >= 4) {
      this.searchRepository
        .searchByText(this.searchTextInput, { limit: 4 })
        .subscribe(res => {
          this.totalAvailableOptions = res.metadata.total;
          this.availableOptions = res.values;
        });
    } else {
      this.availableOptions = [];
      this.totalAvailableOptions = 0;
    }
  }

  submit(selectedResult?: SearchTextResponseModel): void {
    if (selectedResult) {
      // Selected result
      this.navigateToSearchResult(selectedResult);
    } else if (this.totalAvailableOptions === 1) {
      // Only 1 result
      this.navigateToSearchResult(this.availableOptions[0]);
    } else {
      void this.router.navigate(['dashboard', 'search'], {
        queryParams: { search: this.searchText },
        onSameUrlNavigation: 'reload',
        replaceUrl: true,
      });
    }

    this.searchText = '';
    this.searchTextInput = '';
    this.search();
    this.autocomplete.closePanel();
  }

  navigateToSearchResult(selectedResult: SearchTextResponseModel): void {
    switch (selectedResult.type) {
      case 'Contacto':
        void this.router.navigateByUrl(
          `/dashboard/sales/customers/edit/${selectedResult.id}`,
          {
            onSameUrlNavigation: 'reload',
            replaceUrl: true,
          },
        );
        break;
      case 'Producto':
        void this.router.navigateByUrl(
          `/dashboard/product-management/products/edit/${selectedResult.id}`,
          {
            onSameUrlNavigation: 'reload',
            replaceUrl: true,
          },
        );
        break;
      case 'Presupuesto':
        void this.router.navigateByUrl(
          `/dashboard/sales/budgets/edit/${selectedResult.id}`,
          {
            onSameUrlNavigation: 'reload',
            replaceUrl: true,
          },
        );
    }
  }
}

{
  "name": "gafe",
  "version": "2025.4.2-beta",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "prepare": "husky",
    "lint:fix": "ng lint --fix",
    "prettier": "npx lint-staged && npm run lint:fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/cdk": "^19.2.2",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/material": "^19.2.2",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@garrampa/api-services": "^2025.3.4-beta.2",
    "@garrampa/material-table": "^2025.3.2",
    "@kolkov/angular-editor": "^3.0.0-beta.2",
    "angular-oauth2-oidc": "^19.0.0",
    "chart.js": "^4.4.8",
    "luxon": "^3.5.0",
    "marked": "^15.0.7",
    "ng2-charts": "^8.0.0",
    "ngx-markdown": "^19.1.0",
    "ngx-mat-select-search": "^8.0.0",
    "ngx-papaparse": "^8.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular-eslint/builder": "19.2.1",
    "@angular-eslint/eslint-plugin": "19.2.1",
    "@angular-eslint/eslint-plugin-template": "19.2.1",
    "@angular-eslint/schematics": "19.2.1",
    "@angular-eslint/template-parser": "19.2.1",
    "@angular/cli": "~19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@angular/material-luxon-adapter": "^19.2.2",
    "@commitlint/cli": "^19.8.0",
    "@commitlint/config-conventional": "^19.8.0",
    "@types/jasmine": "^5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.14.0",
    "@typescript-eslint/eslint-plugin": "^7.11.0",
    "@typescript-eslint/parser": "^7.11.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "file-saver": "^2.0.5",
    "husky": "^9.1.7",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "lint-staged": "^15.4.3",
    "prettier": "^3.5.3",
    "prettier-eslint": "^16.3.0",
    "typescript": "^5.8.2"
  },
  "volta": {
    "node": "20.11.1"
  }
}

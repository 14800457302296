<mat-toolbar>
  <button
    (click)="clickMenuButton.next()"
    class="hide-desktop hide-tablet"
    mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <a class="logo hide-tablet hide-desktop" routerLink="/dashboard">
    <img src="assets/logo-complete-white.png" />
  </a>
  <app-toolbar-search class="hide-mobile"></app-toolbar-search>
  <button
    class="notifications-button hide-tablet hide-mobile"
    mat-stroked-button
    matTooltip="Tareas pendientes"
    [matMenuTriggerFor]="notificationsMenu">
    <mat-icon>notifications</mat-icon>
    <span>{{ currentUserNotes.length }}</span>
  </button>
  <mat-menu #notificationsMenu="matMenu" [hasBackdrop]="false">
    <div>
      @if (currentUserNotes.length === 0) {
        <mat-list>
          <mat-list-item>No hay notas</mat-list-item>
        </mat-list>
      } @else {
        <mat-selection-list>
          @for (note of currentUserNotes; track note) {
            <mat-list-item (click)="openNote(note.id)">
              <span matListItemTitle>{{ note.noteFrom }}</span>
              <span matListItemLine>{{ note.note }}</span>
              <span matListItemLine
                ><small>{{ note.time }}</small></span
              >
            </mat-list-item>
          }
        </mat-selection-list>
      }
      <div class="notification-menu-buttons-container">
        <button class="big-button view-all" mat-stroked-button>
          Ver todas
        </button>
        <button class="big-button" mat-stroked-button (click)="createNote()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-menu>
  <button class="hide-mobile" mat-icon-button (click)="openHelpDialog()">
    <mat-icon>help</mat-icon>
  </button>
  <button [matMenuTriggerFor]="accountMenu" mat-icon-button>
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #accountMenu="matMenu" [hasBackdrop]="false">
    <div>
      <button mat-menu-item (click)="openAccountSettings()">
        <mat-icon>settings</mat-icon>
        Ajustes de cuenta
      </button>
      <button (click)="logout()" mat-menu-item>
        <mat-icon>logout</mat-icon>
        Cerrar sesión
      </button>
    </div>
  </mat-menu>
</mat-toolbar>

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoadingService } from '../../shared/services/loading.service';

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const loadingService = inject(LoadingService);
  const loading = loadingService.activateLoading();

  if (authService.isLoggedIn()) {
    loadingService.removeLoading(loading);
    return true;
  }

  loadingService.removeLoading(loading);
  // Redirect to the login page
  return router.parseUrl('/login');
};

import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { ResizeWindowService } from '../../shared/services/resize-window.service';
import { CustomSectionTitleService } from '../../shared/services/custom-section-title.service';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    SidebarMenuComponent,
    ToolbarMenuComponent,
    RouterOutlet,
  ],
})
export class BaseLayoutComponent implements OnInit {
  menuOpened = true;
  deviceType: 'mobile' | 'tablet' | 'desktop' = 'desktop';
  currentRouteTitle = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resizeWindowService: ResizeWindowService,
    private customTitleService: CustomSectionTitleService,
  ) {}

  ngOnInit(): void {
    // Get route title from child route
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith({}),
        map(() => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.currentRouteTitle = route.title || '';
      });

    this.customTitleService.getCustomTitle().subscribe(newTitle => {
      this.currentRouteTitle = newTitle;
    });

    this.resizeWindowService.getDeviceType().subscribe(deviceType => {
      this.deviceType = deviceType;
      this.onWindowResize();
    });
  }

  onWindowResize() {
    // Open menu when switching to desktop mode and close it when switching to mobile mode
    this.menuOpened =
      this.deviceType === 'desktop' || this.deviceType === 'tablet';
  }

  clickMenuButton(): void {
    this.menuOpened = !this.menuOpened;
  }
}

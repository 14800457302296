<mat-form-field class="hide-mobile" subscriptSizing="dynamic">
  <mat-label>Buscar</mat-label>
  <input
    (keydown.enter)="submit()"
    (ngModelChange)="inputChange$.next()"
    [(ngModel)]="searchTextInput"
    [matAutocomplete]="auto"
    matInput />
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of availableOptions; track option) {
      <mat-option (click)="submit(option)">
        <mat-chip [class]="option.type.toLowerCase()">{{
          option.type
        }}</mat-chip>
        {{ option.description }}</mat-option
      >
    }
    @if (totalAvailableOptions > 4) {
      <mat-option (click)="submit()">
        <mat-icon>more_horiz</mat-icon>
        Ver todos los resultados
      </mat-option>
    }
  </mat-autocomplete>
  <button aria-label="Buscar" mat-icon-button matSuffix>
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>

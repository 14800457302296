import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MarkdownComponent } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toolbar-help-dialog',
  imports: [
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MarkdownComponent,
  ],
  templateUrl: './toolbar-help-dialog.component.html',
  styleUrl: './toolbar-help-dialog.component.scss',
})
export class ToolbarHelpDialogComponent implements OnInit {
  currentPath: string | undefined = undefined;
  markdownData?: string = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { markdownUrl?: string },
    private http: HttpClient,
    private snackbar: MatSnackBar,
  ) {
    this.currentPath = data.markdownUrl;
  }

  ngOnInit(): void {
    if (this.currentPath) {
      this.http
        .get(this.currentPath, { responseType: 'text' })
        .pipe(
          catchError(() => {
            this.snackbar.dismiss();
            return EMPTY;
          }),
        )
        .subscribe(data => {
          this.markdownData = data;
        });
    }
  }
}

export const environment = {
  environmentName: 'dev',
  version: require('../../package.json').version,
  authConfig: {
    issuer: 'https://auth-stg.garrampa.es',
    debug: true,
    clientId: 'WebAng',
  },
  apiUrl: 'https://api-stg.garrampa.es/api',
};

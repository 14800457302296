<div class="dark">
  <mat-drawer-container autosize>
    <mat-drawer
      [(opened)]="menuOpened"
      [mode]="deviceType === 'mobile' ? 'push' : 'side'">
      <app-sidebar-menu></app-sidebar-menu>
    </mat-drawer>

    <mat-drawer-content>
      <app-toolbar-menu
        (clickMenuButton)="clickMenuButton()"></app-toolbar-menu>
      <div class="section-title">{{ currentRouteTitle }}</div>
      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

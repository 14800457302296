import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionService } from './permission.service';
import { LoadingService } from '../../shared/services/loading.service';

export const permissionGuard = (next: ActivatedRouteSnapshot) => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);
  const loadingService = inject(LoadingService);

  const loading = loadingService.activateLoading();
  if (next.data && next.data['allowedRoles']) {
    const allowedRoles: string[] = next.data['allowedRoles'];

    const userIsAllowed = allowedRoles.some(role => {
      return permissionService.checkUserHasRole(`${role}.view`);
    });

    loadingService.removeLoading(loading);
    if (userIsAllowed) {
      return true;
    } else {
      // Redirect to the login page
      return router.parseUrl('/login');
    }
  }

  loadingService.removeLoading(loading);
  return true;
};
